import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { emailVerfy, handleRegister } from "../../../Redux/Actions/auth";
// import HeaderNav from '../HeaderNav'
import "./SaasOnboarding.css";
import { toast } from "react-hot-toast";
import LoginHeader from "./LoginHeader";
import bgmain from "./SaasAssets/bg-main.svg";
import OtpVerifyMob from "../SaasOnboardingMobile/OtpVerifyMob";
import NewOnboardingHeader from "./Products/NewOnboardingHeader";
// import NewOnboardingHeader from './NewOnboardingHeader';
import { getDeviceId } from "../../../Helpers/utils";
import { constants } from "../../../../src/constants";
import { TextField } from "@mui/material";
import { sendEmailSuccess } from "./SendEmail";

const SaasOtpVeri = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const location = useLocation();

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 4) {
      setOtp(e.target.value);
    }
  };

  useEffect(() => {
    localStorage.setItem("pathname", location.pathname);
  }, []);

  const payload = {
    deviceId: localStorage.getItem("deviceId"),
    email: localStorage.getItem("email"),
    password: localStorage.getItem("password"),
  };

  const handleCheck = (e) => {
    e.preventDefault();
    axios
      .post(`${constants.emailVerifyURL}`, {
        email: localStorage.getItem("email"),
        otp: otp,
      })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          let data = `  <p>Hi ${localStorage.getItem("fullName")},</p>
          <p>I hope this email finds you well. </p>
          <p>I am writing to confirm that your registration with our company has been successfully confirmed. We are delighted to welcome you aboard and look forward to working together.</p>
          <p>Once again, congratulations on becoming part of our community!</p>
          <div style="margin-bottom: 0;">Best Regards,</div>
          <div style="margin-bottom: 0;">iDesign.Market</div>
         `;
     
         let subject="Confirmation of Successful Registration";
         sendEmailSuccess(subject, data, localStorage.getItem("email"));
          // dispatch(handleRegister(payload))
          localStorage.removeItem("deviceId");
          localStorage.removeItem("email");
          // localStorage.removeItem("password");
          // window.location.href = "/profilebuild";
          //  navigateTo("/pricing")

          if (localStorage.getItem("pricing") === "true") {
            navigateTo("/payment");
            localStorage.setItem("pricing", false);
          } else {
            navigateTo("/pricing");
          }
        } else if (res.status == 203) {
          toast.error("Enter correct otp");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goTo = () => {
    localStorage.removeItem("pathname");
    localStorage.setItem("token", "null");
    navigateTo("/signup");
  };

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const convertToAsterisks = (value) => {
    return "*".repeat(value.length);
  };

  return (
    <>
      {/* {isDesktop ? */}
      <div>
        <LoginHeader />
        <div
          className="w-100 main-saas"
          style={{
            backgroundImage: `url(${bgmain})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPositionY: "center",
          }}
        >
          <div className="d-flex flex-column a1">
            <div className="saas-signup-header">Verification</div>
            {/* <span className="saas-signup-span mt-1">We have sent One Time Password on your email id <strong>{localStorage.getItem("email")} </strong> </span> */}
            <div style={{ padding: "24px 24px 0px 24px" }}>
              <span className="saas-signup-span mt-1">
                You will get an OTP via email
              </span>
              {/* <form
                className="flex-column d-flex"
                style={{ marginTop: "1rem" }}
              >
                <input
                  className="saas-form"
                  style={{ height: "44px" }}
                  type="password"
                  value={otp}
                  onChange={handleChange}
                />
              </form> */}
              <TextField
                label="OTP"
                variant="outlined"
                className="saas-form"
                type="password"
                value={otp}
                name="fname"
                onChange={handleChange}
                style={{
                  borderRadius: "12px",
                  width: "100%",
                  margin: "1.25rem 0",
                }}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              />
              <button className="create222 mt-4" onClick={handleCheck}>
                Verify
              </button>
              <div className="d-flex flex-column mt-4"></div>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid #e0e3e6",
                padding: "24px",
              }}
            >
              <div className="saas-signup-span" style={{ width: "unset" }}>
                Didn’t receive the verification OTP?
              </div>
              <div
                className="saas-resend"
                style={{
                  color: "#0084EF",
                  textDecoration: "underline",
                  marginLeft: "10px",
                  textUnderlineOffset: "0.2rem",
                }}
                onClick={() => {
                  emailVerfy(payload, navigateTo);
                }}
              >
                Resend
              </div>
              {/* <div className="saas-resend" onClick={goTo}>Change the Email address</div> */}
            </div>
          </div>
        </div>
      </div>

      {/* : <OtpVerifyMob/> } */}
    </>
  );
};

export default SaasOtpVeri;
